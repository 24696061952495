export default function MenuArrowSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="white">
      <path
        d="M7.99982 9.65561L2.97464 4.63043C2.89654 4.55233 2.7699 4.55233 2.6918 4.63043L1.98469 5.33754C1.90659 5.41565 1.90659 5.54228 1.98469 5.62038L7.64657 11.2823C7.84183 11.4775 8.15842 11.4775 8.35368 11.2823L14.0156 5.61969C14.0937 5.54159 14.0937 5.41495 14.0156 5.33685L13.3085 4.62974C13.2304 4.55164 13.1038 4.55164 13.0257 4.62974L7.99982 9.65561Z"
        fill="white"
      />
    </svg>
  );
}
